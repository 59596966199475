import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { StaticTemplate } from '../../../templates/staticTemplate'
import { Layout } from '../../../components/layouts/layout'
import { PageMeta } from '../../../components/page-meta'
import { CalculatorSectionModule } from '../../../components/modules/CalculatorSectionModule'
import { PFStudentLoanCalculator } from '../../../components/calculators'
import { anchorLinkSections, CrossSellTout } from './constants'
import { usePages } from '../constants'

const StudentLoanPage = ({ pageContext, location }) => {
  const [t] = useTranslation()
  const { calculatorsPage, personalFinancePage } = usePages()

  const pageData = [
    {
      sectionTitle: t('StudentLoanPage.subTitle', {
        defaultValue: 'When will I pay off my student loans?',
      }),
      richBody: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: t('StudentLoanPage.textContent', {
                  defaultValue:
                    'Estimate how long it might take to pay back your student loans based on your anticipated income. Knowing how income impacts your loan repayment can help you make better decisions about your personal budget.',
                }),
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
  ]

  return (
    <StaticTemplate pageContext={pageContext}>
      {({ navMenus }) => (
        <Layout
          location={location}
          pageContext={{ ...pageContext, pathname: personalFinancePage.urlPath }}
          menu={{ ...navMenus, subnav: [calculatorsPage] }}
          showSubnav
        >
          <PageMeta
            language={pageContext.langKey}
            title={t('StudentLoanPage.pageTitle', {
              defaultValue: 'Student Loan Payoff Calculator',
            })}
            pathname="/calculators/personal-finance/student-loan-payoff"
          />
          <CalculatorSectionModule
            pageTitle={t('StudentLoanPage.title', {
              defaultValue: 'Personal Finance Calculators',
            })}
            sections={pageData}
            calculator={<PFStudentLoanCalculator />}
            anchorLinkSections={anchorLinkSections()}
          />
          <CrossSellTout />
        </Layout>
      )}
    </StaticTemplate>
  )
}

StudentLoanPage.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export { StudentLoanPage }
export default StudentLoanPage
